<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" md="6">
        <h1>Reset - New Password Creation</h1>
        <br />
        <p v-if="loading">Loading...</p>
        <p v-else>
          Please enter and confirm your new password. <br />
          <br />
          Note: The passwords must match and be at least 8 characters in length.
        </p>
      </v-col>
    </v-row>

    <v-form ref="form" @submit.prevent="submit">
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="password1"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="9" md="6">
          <v-text-field
            v-model="password2"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Confirm Password"
            hint="At least 8 characters"
            class="input-group--focused"
            @click:append="show2 = !show2"
            counter
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="9" md="6">
          <v-alert v-if="errors.length" type="warning">
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </v-alert>

          <v-alert v-if="success" type="success"
            >Your password has been changed successfully. Redirecting to login
            in 2 seconds.</v-alert
          >

          <v-btn v-if="!sending && !success" @click="submit">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
  
  <script>
import API from "@/plugins/API";

export default {
  data() {
    return {
      code: null,
      loading: true,
      email: null,
      errors: [],
      sending: false,
      success: false,
      show1: false,
      show2: false,
      password1: null,
      password2: null,
    };
  },
  methods: {
    async submit() {
      try {
        this.errors = [];

        if (this.password1 != this.password2) {
          this.errors.push("Passwords do not match.");
        }

        if (!this.errors.length) {
          this.sending = true;
          let reset_response = await API().post(
            "/api/auth/reset-password",
            {
              code: this.code,
              password: this.password1,
              passwordConfirmation: this.password2,
            },
            {
              headers: {
                Authorization: null,
              },
            }
          );

          if (reset_response.status == 200) {

            this.success = true;
            setTimeout(()=>{
                this.$router.push({path: '/login'})
            }, 2000)
          }
        }
      } catch (error) {
        this.errors.push("There was an error saving");
      }
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.loading = false;
    } else {
      console.log("empty");
    }
  },
};
</script>
  
  <style>
</style>